import { action, computed, observable } from 'mobx';
import ModuleStore from 'store/modules/ModuleStore';
import CreditApi from 'api/CreditApi';

class CreditStore extends ModuleStore {
    @observable prices;
    @observable settings;
    @observable creditFromApi;

    @observable form = {
        phone: '+',
        email: null,
        price: null
    }

    constructor(rootStore) {
        super(rootStore);
        this.rootStore = rootStore;
    }

    @action
    setCreditFromApi(data) {
        this.creditFromApi = data;
    }

    @action
    getInitialCreditPrices(slipwayOnly = false) {
        this.setLoading(true);
        const harborId = this.rootStore.harbor.id;

        CreditApi.getDoorPrices(harborId)
            .then(({ data }) => {
                this.setPrices(data, slipwayOnly)
                this.setLoading(false);
            })
            .catch(() => this.setLoading(false));
    }

    @action.bound
    setPrices(value, slipwayOnly) {
        if (value) {
            if (!slipwayOnly) {
                this.prices = value.map((doorData) => {
                    // TODO: Delete ternary operator from 06.04
                    return { price: doorData.price, name: doorData.name ? doorData.name : doorData['door'].name }
                });
            } else {
                const doorId = this.rootStore.harbor.harbakioskSettings.actDoor;
                const door = value.find((item)=>{return item.id = doorId;});
                if (door) {
                    this.prices = [{ price: door.price, name: door.name ? door.name : door['door'].name }];
                }
            }
        }
    }

    @action
    getActSettings() {
        this.setLoading(true);
        const harborId = this.rootStore.harbor.id;

        CreditApi.getActSettings(harborId)
            .then(({ data }) => {
                this.setSettings(data);
                this.setLoading(false);
            })
            .catch(() => this.setLoading(false));
    }

    @action.bound
    setSettings(value) {
        this.settings = value;
    }

    @computed
    get createCreditPaymentPromise() {
        return () => {
            return new Promise((resolve, reject) => {
                CreditApi.postTopUp(this.formRequestBody, this.rootStore.harbor.id, this.rootStore.locale.locale)
                    .then(({ data }) => {
                        this.savePaymentRequest(data.orderId, Number(this.form.price));
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
            })
        }
    }

    @computed
    get formRequestBody() {
        const { price, phone, email } = this.form;
        return {
            amount: Number(price),
            phone: (phone && phone.length > 4) ? phone : null,
            email,
            source: 'kiosk',
        }
    }
}

export default CreditStore;
