import Api from 'api/api';

class BookingApi {
    checkPrice(body) {
        return Api.post('/bookings/check_prices', body);
    }

    createBooking(body, locale) {
        return Api.post(`/bookings/anon?locale=${locale}`, body);
    }

    getBookingByPaymentOrderId(orderId) {
        return Api.get(`/public/bookings/${orderId}`);
    }

    markAsPaid(orderId, gateway, fee = 0) {
        return Api.patch(`/public/bookings/verify_nets/${orderId}?gateway=${gateway}&fee=${fee}`);
    }

    listAvailableDocks(harborId, date) {
        return Api.get(`/public/harbor/${harborId}/bridge?fromDate=${date['fromDate']}&toDate=${date['toDate']}`);
    }

    listAvailableDockBerths(harborId, date, dockId) {
        return Api.get(`/public/harbor/${harborId}/bridge/${dockId}?fromDate=${date['fromDate']}&toDate=${date['toDate']}`);
    }
}

export default new BookingApi();
